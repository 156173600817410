body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: 'Bw Surco';
  src: url('static/BwSurco-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Surco';
  src: url('static/BwSurco-Medium.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Surco';
  src: url('static/BwSurco-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Bw Surco", sans-serif;
}

p,
span {
  font-family: "Bw Surco", sans-serif;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
